<template>
  <div id="login">
    <el-card class="login_form">
      <div class="form_title">登录</div>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="name">
          <img src="~assets/img/login/number.png" alt="number.png" />
          <el-input v-model="form.name" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <img src="~assets/img/login/password.png" alt="password.png" />
          <el-input
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')"
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      form: {
        name: "",
        password: ""
      },
      rules: {
        name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请选输入密码", trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      const md5Password = md5(this.form.password);
      this.axios({
        method: "post",
        url: "/login",
        params: {
          username: this.form.name,
          password: md5Password
        }
      })
        .then(res => {
          if (res.data.success === 1) {
            this.$message.success(res.data.msg);
            sessionStorage.setItem(
              "user",
              JSON.stringify(res.data.body.loginUser)
            );

            if (res.data.body.loginUser.duType === 1) {
              this.$router.push("/manage/manageCockpit");
            } else {
              window.location.href = res.data.body.loginUser.duHost + "/index";
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => console.log(error));
    }
  }
};
</script>
<style lang="less" scoped>
#login {
  background-color: #f5f5f6;
  overflow: hidden;
  .login_form {
    width: 480px;
    height: 360px;
    margin: 100px auto 377px;
    .form_title {
      font-size: 24px;
      font-weight: 500;
      color: #314558;
      margin-bottom: 30px;
      letter-spacing: 10px;
      text-align: center;
      margin: 40px;
    }
    .el-form {
        text-align: center;
      .el-form-item {
        img {
          vertical-align: middle;
        }
        .el-input {
          width: 70%;
        }
      }
    }
    .el-button {
      width: 360px;
      margin-top: 18px;
      margin-left: 15px;
    }
  }
}
</style>
<style lang="less">
#login {
  .el-input__inner {
    border: none;
    border-bottom: 1px solid #e4e4e4;
  }
  .el-form-item__error {
    left: 20%;
  }
}
</style>
